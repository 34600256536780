<template>
  <div class="colelTest">
	  <colel-card
		  :title="
           $t('settings.titleColelTest')
      "
		  :isLoading="isLoading"
		  :icon="'mdi-note-edit'"
	  >
      <template v-slot:extra-header>
        <v-tooltip
		   bottom
	   >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
			  @click="save"
			  color="primary"
			  elevation="2"
			  v-bind="attrs"
			  v-on="on"
		  >
<!--              <v-icon>mdi-plus-circle</v-icon>-->
			{{ $t("settings.save") }}
            </v-btn>
          </template>
          <span>{{ $t("settings.save") }}</span>
        </v-tooltip>
      </template>
      <template v-slot:content>
    <v-form >
    <v-container>
      <v-row>
        <v-col
		   cols="12"
		   xs="12"
		   sm="6"
		   md="4"
	   >
          <v-text-field
			type="number"

			v-model="settings.oral_exam_pay"
			:rules="number"
			:label="$t('settings.oral_exam_pay')"
			required
		></v-text-field>
        </v-col>

        <v-col
		   cols="12"
		   xs="12"
		   sm="6"
		   md="4"
	   >
          <v-text-field
			type="number"
			v-model="settings.written_test_pass_grade"
			:rules="number"
			:label="$t('settings.written_test_pass_grade')"
			required
		></v-text-field>
        </v-col>

        <v-col
		   cols="12"
		   xs="12"
		   sm="6"
		   md="4"
	   >
          <v-text-field
			type="number"
			v-model="settings.written_test_pay"
			:rules="number"
			:label="$t('settings.written_test_pay')"
			required
		></v-text-field>
        </v-col>

      </v-row>
    </v-container>
  </v-form>
		      </template>

	 </colel-card>
  </div>
</template>

<script>
import {postSettings} from './colelTest'
import {getSettings} from "./colelTest";
import constants from "@/constants";
const a = {
  oral_exam_pay: null,
  written_test_pass_grade	: null,
  written_test_pay: null,
}

export default {
  name: "colelTest",
  components: {},
  props: {
    pickLevel: Number
  },
  data() {
    return {
settings : a,
      isLoading: true,
	 number: [
        v => !!v || 'חובה',
        v => /^\d+$/.test(v) || 'יש למלאות רק מספרים',
		]
	 // number: [
      //   v =>  '^[0-9]$'.test(v) || 'חובה מספרים'
		// ]

    };
  },
  mounted() {
  },
  methods: {
    async save(){
      try {
        if (this.settings.colel_level === null){
          this.settings.colel_level = this.pickLevel
        }
        const {data} = await postSettings(this.settings)
        this.settings = data
        this.isLoading = true

       await this.get()
        this.$toast.add({
                          severity: 'success',
                          summary: this.$t('success.settingsReportSuccess'),
                          detail:
                              this.$t('success.settingsReportSuccessDetails'),
                          life: constants.LIFE_TOAST
                        });
      } catch (e) {
        this.$toast.add({
                          severity: 'error',
                          summary: this.$t('errors.settingsReportError'),
                          detail:
                              this.$t('errors.settingsReportErrorDetails'),
                          life: constants.LIFE_TOAST
                        });
      }
    },
    async get(){
      try {
        this.isLoading = true
        const {data} = await getSettings(this.pickLevel)
        this.settings = data
	   this.isLoading = false
      } catch (e) {
        console.log(e)
      }
    }
  },
  watch: {
    pickLevel(newValue, oldValue) {
      this.get()
    }
  },
  created() {
    this.get()
  },
};
</script>

<style scoped></style>
