import axios from "axios";


export async function getSettings(colel_level) {
    return await axios.get(`/manager/test/settings?colel_level=${colel_level}`,);
}

export async function postSettings(testSettings) {
    Object.keys(testSettings).forEach(key => {
        testSettings[key] = parseInt(testSettings[key])
                                      })
    return await axios.post(`/manager/settings/test`,testSettings);
}
