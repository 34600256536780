import axios from "axios";

export async function getSettings(colel_level) {
        return await axios.get(`/manager/payments?colel_level=${colel_level}`);
}

export async function postSettings(settings) {
    // Object.keys(testSettings).forEach(key => {
    //     testSettings[key] = parseInt(testSettings[key])
    // })
    return await axios.post(`/manager/payments?colel_level=`,settings);
}