<template >
  <div class="SystemSettings">
	 <div class="p-grid p-jc-center">

	 	<div class=" p-col-12 p-md-9 p-lg-6  p-p-5 p-jc-center p-grid">
		    <div class="p-mb-4 p-grid p-jc-center">
			    <label
				    class="p-col-6"
				    for="pickLevel"
			    >{{ $t('settings.pickLevel') }}</label >
			     <InputNumber
					id="pickLevel"
					min="0"
					v-model="pickLevel"
					showButtons
				/>
		    </div >

			 <colelLevel
				 :pickLevel="pickLevel"
				 class="p-col-12"
			 ></colelLevel >
			 <colelTest
				 :pickLevel="pickLevel"
				 class="p-pt-4 p-col-12"
			 ></colelTest >
		    <lockColels
				 class="p-pt-4 p-col-12"
			 ></lockColels >



		 </div >

	 </div >
  </div >
</template >

<script >
import colelLevel from "./components/colelLevel.vue";
import colelTest from "./components/colelTest.vue";
import lockColels from "./components/lockColels.vue";
import {mapState, mapActions} from "vuex";
import constants from "@/constants";

export default {
  name: "SystemSettings",
  components: {
    colelLevel,
    colelTest,
    lockColels,
  },
  mounted() {
  },
  data() {
    return {
      pickLevel: '0'
    };
  },
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapState(["colel"])
  }
};
</script >

