<template>
  <div class="lock-colels">

	  <colel-card
		  :title=" $t('settings.lockTitle') "
		  :icon="'mdi-lock'"
	  >
      <template v-slot:extra-header>

        <v-tooltip
		   bottom
	   >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
			  @click="lockAllColels"
			  color="primary"
			  elevation="2"
			  v-bind="attrs"
			  v-on="on"
		  >
<!--              <v-icon>mdi-plus-circle</v-icon>-->
			{{ $t("settings.lock") }}
            </v-btn>
          </template>
          <span>{{ $t("settings.lock") }}</span>
        </v-tooltip>
      </template>
      <template v-slot:content>
		<div class="p-d-flex p-jc-center">
  <Calendar
	  class="p-p-3"
	  v-model="selectedDate"
	  view="month"
	  dateFormat="mm/yy"
	  :yearNavigator="true"
  ></Calendar >
</div>

		      </template>

	 </colel-card>
  </div>
</template>

<script>

import {dateToServer} from "@/utils/dateFormat";

export default {
  name: "lockColels",
  components: {},
  data() {
    return {
      selectedDate: new Date(),
    };
  },
  mounted() {
  },
  methods: {
  lockAllColels(){

    alert(`${dateToServer(this.selectedDate)}, נעילה עדיין לא עובד ;`)
  }
  },

};
</script>

<style scoped></style>
